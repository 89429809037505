<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card raised outlined>
              <v-dialog persistent v-model="dialog" max-width="1000"
                        transition="dialog-bottom-transition">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn color="primary" dark class="ml-auto ma-3 mr-3" @click="refreshData(true)">
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <!--                    <v-btn color="primary" dark class="ma-3 ml-0" v-on="on">-->
                    <!--                      Add-->
                    <!--                      <v-icon class="ml-2" small>fas fa-plus-circle</v-icon>-->
                    <!--                    </v-btn>-->
                  </div>
                </template>
                <v-card>
                  <v-toolbar
                      dark
                      color="primary"
                  >
                    <v-btn
                        icon
                        dark
                        @click="closeDialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span v-if="editedItem.id">Edit "{{ editedItem.email }}"</span>
                      <span v-else>Create User</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!validationRules.valid"
                          dark
                          text
                          @click="saveItem(editedItem)"
                      >
                        Save
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card flat>
                    <v-card-text>
                      <v-form
                          class="custom_form"
                          ref="form"
                          v-model="validationRules.valid"
                          lazy-validation
                      >
                        <v-row>
                          <v-col cols="12" sm="4">
                            <v-text-field clearable
                                          outlined
                                          dense v-model="editedItem.name" :counter="300"
                                          maxLength="300"
                                          :rules="validationRules.nameRules"
                                          label="Name*"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" v-if="!editedItem.is_edit">
                            <v-text-field v-model="editedItem.email" :counter="100"
                                          clearable
                                          outlined
                                          dense
                                          :rules="validationRules.emailRules" label="Email*"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field v-model="editedItem.password" :counter="20"
                                          clearable
                                          outlined
                                          dense
                                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                          :type="show ? 'text' : 'password'"
                                          @click:append="show = !show" label="Password"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn :disabled="!validationRules.valid" color="blue darken-1" text @click="saveItem(editedItem)">
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="detailDialog" fullscreen>
                <v-card>
                  <UserDetails :item="editedItem" @closeDetailsDialog="detailDialog = false"/>
                </v-card>
              </v-dialog>
              <v-divider class="m-0"></v-divider>
              <v-col cols="12">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="custom-expansion-panel-header" color="primary">
                      <div class="text-center font-weight-bold">
                        Filters
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form>
                        <v-row>
                          <v-col cols="12" sm="4">
                            <v-select
                                clearable
                                dense
                                outlined
                                hide-details
                                v-model="searchFormData.typeOfUserSearch"
                                :items='[{value: "1", title: "Panelist"},{value: "2", title: "Client"}]'
                                item-text="title"
                                item-value="value"
                                label="Type of User">
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                                clearable
                                dense
                                outlined
                                hide-details
                                v-model="searchFormData.statusSearch"
                                :items='[{value: "0", title: "Inactive"},{value: "1", title: "Active"}]'
                                item-text="title"
                                item-value="value"
                                label="Status">
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <button @click="hitAdvanceSearch(false)" :disabled="loading" type="submit" style="width: 100%"
                                  class="btn btn-primary ml-auto mr-2">
                            Search
                            <v-icon class="ml-2" small>fas fa-search</v-icon>
                          </button>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <button :disabled="loading" style="width: 100%" @click="hitAdvanceSearch(true)"
                                  class="btn btn-warning ml-auto">
                            Reset
                            <v-icon class="ml-2" small>fas fa-times</v-icon>
                          </button>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-card-title>
                Users ({{ totalRecords }})
              </v-card-title>
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
              >
              </v-skeleton-loader>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  @update:items-per-page="refreshData(false)"
                  :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="800"
                  class="elevation-0 custom-actions"
              >
                <template v-slot:item.type="{ item }">
                  <div class="text-truncate">
                    <p v-if="item.type === 1">Panelist</p>
                    <p v-if="item.type === 2">Client</p>
                  </div>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-switch
                      v-model="item.status"
                      v-bind:color="item.status === 1 ? 'success': ''"
                      item-value="value"
                      @change="updateStatus($event,item.id,'status')"
                  ></v-switch>
                </template>
                <template v-slot:item.email="{ item }">
                  <div class="text-truncate">
                    <p v-html="item.email"></p>
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ formatDateAndTime(item.created_at) }}
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-btn color="primary" @click="showEditDialog(item)" dark
                           class="mr-2">
                      View
                      <v-icon dark right small>fas fa-eye</v-icon>
                    </v-btn>
                    <!--                    <v-btn-->
                    <!--                        @click="deleteItem(item)"-->
                    <!--                        color="red" dark class="mr-2" v-if="item.is_primary === 0 && $store.state.auth.user.id !== item.id">-->
                    <!--                      Delete-->
                    <!--                      <v-icon dark right small>fas fa-trash</v-icon>-->
                    <!--                    </v-btn>-->
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  USERS_LIST,
  CREATE_USER,
  UPDATE_USER,
  CLEAR_USER_ERRORS,
  DELETE_USER, GET_USER_DETAILS, UPDATE_USER_STATUS
} from "@/core/services/store/users.module";
import {bus} from "@/main";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import UserDetails from "@/view/pages/realstate/users/inc/UserDetails";

export default {
  name: 'users',
  components: {UserDetails},
  data() {
    return {
      draw: 1,
      searchQuery: "",
      loading: true,
      isLoaded: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      headers: [
        {
          text: 'First Name',
          value: 'first_name',
          name: 'first_name',
          filterable: true,
          sortable: false
        },
        {
          text: 'Middle Name',
          value: 'middle_name',
          name: 'middle_name',
          filterable: true,
          sortable: false
        },
        {
          text: 'Last Name',
          value: 'last_name',
          name: 'last_name',
          filterable: true,
          sortable: false
        },
        {
          text: 'Email',
          value: 'email',
          name: 'email',
          filterable: true,
          sortable: false
        },
        {
          text: 'Type',
          value: 'type',
          name: 'type',
          filterable: false,
          sortable: false
        },
        {
          text: "Status",
          value: "status",
          name: "status",
          filterable: false,
          sortable: false
        },
        {
          text: "Created",
          value: "created_at",
          name: "created_at",
          filterable: false,
          sortable: true
        },
        {text: 'Action', value: 'actions', data: null, name: null, filterable: false, sortable: false},
      ],
      validationRules: {
        valid: true,
        nameRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than or equal to 3 characters",
          v => (v && v.length <= 300) || "Must be less than or equal to 300 characters"
        ],
        emailRules: [
          v => !!v || "This field  is required",
          v => (v && v.length <= 100) || "Must be less than 100 characters",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],
        passwordRules: [
          v => !!v || "Password is required",
          v => (v && v.length <= 20) || "Password must be less than 20 characters",
          v => (v && v.length >= 6) || "Password must be at least 6 characters"
        ],
        multipleRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Permission(s)';
            else return true;
          }
        ]
      },
      items: [],
      dialog: false,
      detailDialog: false,
      show: false,
      editedItem: {},
      searchFormData: {}
    }
  },
  watch: {
    // params: {
    //   handler() {
    //     this.loadData().then(response => {
    //       this.items = response.items;
    //       this.totalRecords = response.total;
    //     });
    //   },
    //   deep: true
    // },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_USER_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) bus.$emit("moduleFilesDeletedByModal");
      if (!val) this.activeTab = 0;
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].data +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "User's Panel", route: "users"},
      {title: "Users"}
    ]);
    this.refreshData(true);
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query += '&' + searchFormDataQuery
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 2 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(USERS_LIST, {q: query, columns: this.columns}).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    refreshData(loadDep = true) {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        // if (this.$store.state.auth.user.type === 1) {
        //   if (loadDep) this.getAdminPermissions();
        // } else {
        //   if (loadDep) this.getVendorPermissions();
        // }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_USER_DETAILS, item.id).then((data) => {
        this.editedItem = data.records || {};
        this.detailDialog = true;
      });
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = CREATE_USER;
        let id = item.id;
        let data = {
          data: item
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_USER;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_USER_ERRORS);
        // save the record
        this.$store.dispatch(method, {'slug': id, data: data.data}).then(() => {
          if (method === 'update_users') {
            this.showEditDialog(item);
          } else {
            this.dialog = false;
          }
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          // this.editedItem.id = response.records.id
          // if (!id) {
          //   this.items.unshift(this.editedItem)
          // }
          // this.dialog = !this.dialog
          if (this.$route.query.hasOwnProperty('addModule')) {
            window.close();
          }
        })
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to delete?", clr: 'red', callback: function () {
          this.$store.dispatch(DELETE_USER, {
            'user_id': item.id
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    hitAdvanceSearch(reset = false) {
      this.pagination.page = 1
      if (reset) this.searchFormData = {};
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    updateStatus(status, cId, column) {
      this.$store.dispatch(CLEAR_USER_ERRORS);
      let data = {
        status: status,
        column: column
      };
      this.$store.dispatch(UPDATE_USER_STATUS, {'slug': cId, data: data}).then(() => {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      })
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.users.errors
    })
  }
};
</script>