<template>
  <div>
    <v-toolbar
        dark
        color="#00004d"
    >
      <v-btn
          icon
          dark
          @click="$emit('closeDetailsDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0 hidden-sm-and-down">
        <span v-html="item.first_name + ' ' + item.middle_name + ' ' + item.last_name"></span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Profession</v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Profession:</h6>
          <p v-html="item.profession ? item.profession : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Other Profession:</h6>
          <p v-html="item.other_profession ? item.other_profession : '-'"></p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Profile/Personal Details</v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Salutation:</h6>
          <p v-html="item.salutation ? item.salutation : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>First Name:</h6>
          <p v-html="item.first_name ? item.first_name : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Middle Name:</h6>
          <p v-html="item.middle_name ? item.middle_name : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Last Name:</h6>
          <p v-html="item.last_name ? item.last_name : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>DOB:</h6>
          <p v-html="item.dob ? formatMomentDate(item.dob) : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Nationality:</h6>
          <p v-html="item.nationality ? item.nationality : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Gender:</h6>
          <p v-html="item.gender ? item.gender : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Email:</h6>
          <p v-html="item.email ? item.email : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Phone:</h6>
          <p v-html="item.phone ? item.phone : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Picture:</h6>
          <p v-if="item.user_image">
            <a :href="item.image_public_url" target="_blank">View</a>
          </p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>CV:</h6>
          <p v-if="item.user_cv">
            <a :href="item.cv_public_url" target="_blank">View</a>
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Personal Address</v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Postal Address:</h6>
          <p v-html="item.per_postal_address ? item.per_postal_address : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Country of Residence:</h6>
          <p v-html="item.per_country ? item.per_country : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>City of Residence:</h6>
          <p v-html="item.per_city ? item.per_city : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Telephone:</h6>
          <p v-html="item.per_tel ? item.per_tel : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Fax:</h6>
          <p v-html="item.per_fax ? item.per_fax : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Mobile:</h6>
          <p v-html="item.per_mobile ? item.per_mobile : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Email:</h6>
          <p v-html="item.per_email ? item.per_email : '-'"></p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Business Address</v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Postal Address:</h6>
          <p v-html="item.bus_postal_address ? item.bus_postal_address : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Country:</h6>
          <p v-html="item.bus_country ? item.bus_country : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>City:</h6>
          <p v-html="item.bus_city ? item.bus_city : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Telephone:</h6>
          <p v-html="item.bus_tel ? item.bus_tel : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Fax:</h6>
          <p v-html="item.bus_fax ? item.bus_fax : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Mobile:</h6>
          <p v-html="item.bus_mobile ? item.bus_mobile : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Email:</h6>
          <p v-html="item.bus_email ? item.bus_email : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Preferred method of communication:</h6>
          <p v-html="item.communication_via ? item.communication_via : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Communication type:</h6>
          <p v-html="item.communication_type ? item.communication_type : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Working Days:</h6>
          <p v-html="item.work_days ? item.work_days : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Working Hours:</h6>
          <p v-html="item.work_hours ? item.work_hours : '-'"></p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Academic Qualification(s)</v-card-title>
            <v-card-text class="white--text text-center">
              Please provide photocopied evidence of your university degree duly certified in the
              country of issuance with a legalized translation into English. Please attach evidence of
              any other relevant academic qualifications, if any
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="item.user_degrees.length > 0" v-for="(v, index) in item.user_degrees" :key="index">
        <v-col cols="12" sm="3">
          <h6>Degree Title:</h6>
          <p v-html="v.degree ? v.degree : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Date:</h6>
          <p v-html="v.degree_date ? formatMomentDate(v.degree) : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Institution Name:</h6>
          <p v-html="v.institution_name ? v.institution_name : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Location:</h6>
          <p v-html="v.degree_location ? v.degree_location : '-'"></p>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider style="margin: 0"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Languages</v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="item.user_languages.length > 0" v-for="(v, index) in item.user_languages" :key="index">
        <v-col cols="12" sm="4">
          <h6>Language:</h6>
          <p v-html="v.language ? v.language : '-'"></p>
        </v-col>
        <v-col cols="12" sm="4">
          <h6>Written:</h6>
          <p v-html="v.language_written ? v.language_written : '-'"></p>
        </v-col>
        <v-col cols="12" sm="4">
          <h6>Spoken:</h6>
          <p v-html="v.language_spoken ? v.language_spoken : '-'"></p>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider style="margin: 0"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Employment History for legal professionals</v-card-title>
            <v-card-text class="white--text text-center">
              Outline your employment history for at least the past 6 years and attach any relevant
              employment certificates.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="item.user_emp_history.length > 0" v-for="(v, index) in item.user_emp_history" :key="index">
        <v-col cols="12" sm="3">
          <h6>Organization Name:</h6>
          <p v-html="v.emp_org_name ? v.emp_org_name : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Position:</h6>
          <p v-html="v.emp_org_position ? v.emp_org_position : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Date:</h6>
          <p v-html="v.emp_org_date ? formatMomentDate(v.emp_org_date) : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Responsibility in Brief:</h6>
          <p v-html="v.emp_org_responsibility ? v.emp_org_responsibility : '-'"></p>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider style="margin: 0"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Employment History for non-legal
              professionals
            </v-card-title>
            <v-card-text class="white--text text-center">
              Outline your employment history for at least the past 8 years and attach any relevant
              employment certificates
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="item.user_emp_history_non_legal.length > 0" v-for="(v, index) in item.user_emp_history_non_legal"
             :key="index">
        <v-col cols="12" sm="3">
          <h6>Organization Name:</h6>
          <p v-html="v.emp_org_name_non_legal ? v.emp_org_name_non_legal : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Position:</h6>
          <p v-html="v.emp_org_position_non_legal ? v.emp_org_position_non_legal : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Date:</h6>
          <p v-html="v.emp_org_date_non_legal ? formatMomentDate(v.emp_org_date_non_legal) : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Responsibility in Brief:</h6>
          <p v-html="v.emp_org_responsibility_non_legal ? v.emp_org_responsibility_non_legal : '-'"></p>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider style="margin: 0"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Fields of Expertise</v-card-title>
            <v-card-text class="text-center white--text">
              Please select areas of Specialization
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <h6>Fields of Expertise:</h6>
          <p v-html="item.fields_of_expertise ? item.fields_of_expertise : '-'"></p>
        </v-col>
        <v-col cols="12" sm="6">
          <h6>Other:</h6>
          <p v-html="item.other_fields_of_expertise ? item.other_fields_of_expertise : '-'"></p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Jurisdictional Experience</v-card-title>
            <v-card-text class="white--text text-center">
              Outline the national jurisdiction(s) and legal system(s), to which your academic
              qualification(s) and practice pertain
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="item.user_jurisdictional_experience.length > 0"
             v-for="(v, index) in item.user_jurisdictional_experience" :key="index">
        <v-col cols="12" sm="12">
          <h6>Jurisdictional Experience:</h6>
          <p v-html="v.jurisdictional_exp ? v.jurisdictional_exp : '-'"></p>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider style="margin: 0"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Dispute Resolution/Arbitration Training</v-card-title>
            <v-card-text class="white--text text-center">
              Please set out any training programs in dispute resolution and arbitration that you have
              taken and provide evidence of completion of such training(s)
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="item.user_dispute_resolution_arbitration_training.length > 0"
             v-for="(v, index) in item.user_dispute_resolution_arbitration_training"
             :key="index">
        <v-col cols="12" sm="3">
          <h6>Program Title:</h6>
          <p v-html="v.dispute_resolution_program_title ? v.dispute_resolution_program_title : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Date:</h6>
          <p v-html="v.dispute_resolution_training_date ? formatMomentDate(v.dispute_resolution_training_date) : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Institute Name:</h6>
          <p v-html="v.dispute_resolution_institute_name ? v.dispute_resolution_institute_name : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Other Relevant Information:</h6>
          <p v-html="v.dispute_resolution_other_information ? v.dispute_resolution_other_information : '-'"></p>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider style="margin: 0"></v-divider>
        </v-col>
      </v-row>

      <v-row v-if="item.experience_type === 'Arbitration'">
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Arbitration Experience</v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="item.experience_type === 'Arbitration' && item.user_arbitration_experience.length > 0"
             v-for="(v, index) in item.user_arbitration_experience"
             :key="index">
        <v-col cols="12" sm="3">
          <h6>Institution Name and/or Case Ref. No.:</h6>
          <p v-html="v.arbitration_experience_institution_name ? v.arbitration_experience_institution_name : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Nature of dispute:</h6>
          <p v-html="v.arbitration_experience_nature_of_dispute ? v.arbitration_experience_nature_of_dispute : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Date of award rendered:</h6>
          <p v-html="v.arbitration_experience_date_of_award ? formatMomentDate(v.arbitration_experience_date_of_award) : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Approximate sum in dispute:</h6>
          <p v-html="v.arbitration_experience_approximate_sum ? v.arbitration_experience_approximate_sum : '-'"></p>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider style="margin: 0"></v-divider>
        </v-col>
      </v-row>
      <v-row v-if="item.experience_type === 'Arbitration'">
        <v-col cols="12" sm="3">
          <h6>Total Experience in Arbitration (in years):</h6>
          <p v-html="item.number_of_exp_in_arbitration ? item.number_of_exp_in_arbitration : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Are you already empaneled as an Arbitrator with any other Institution?</h6>
          <p v-html="item.exp_in_arbitration_is_other_inst ? item.exp_in_arbitration_is_other_inst : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3" v-if="item.exp_in_arbitration_is_other_inst === 'Yes'">
          <h6>Other Institution Name:</h6>
          <p v-html="item.exp_in_arbitration_other_inst_name ? item.exp_in_arbitration_other_inst_name : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Number of Arbitration matters conducted by you as an Arbitrator in Ad-hoc arbitrations (as sole arbitrator
            or otherwise):</h6>
          <p v-html="item.number_of_arbitration_matters ? item.number_of_arbitration_matters : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Number of cases conducted by you as a member of arbitral tribunal (sole arbitrator or otherwise) under the
            aegis of an Arbitral Institution:</h6>
          <p v-html="item.number_of_arbitration_cases ? item.number_of_arbitration_cases : '-'"></p>
        </v-col>
      </v-row>

      <v-row v-if="item.experience_type === 'Mediation'">
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Mediation Experience</v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="item.experience_type === 'Mediation' && item.user_mediation_experience.length > 0"
             v-for="(v, index) in item.user_mediation_experience"
             :key="index">
        <v-col cols="12" sm="3">
          <h6>Institution Name and/or Case Ref. No.:</h6>
          <p v-html="v.mediation_experience_institution_name ? v.mediation_experience_institution_name : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Nature of dispute:</h6>
          <p v-html="v.mediation_experience_nature_of_dispute ? v.mediation_experience_nature_of_dispute : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Date of award rendered:</h6>
          <p v-html="v.mediation_experience_date_of_award ? formatMomentDate(v.mediation_experience_date_of_award) : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Approximate sum in dispute:</h6>
          <p v-html="v.mediation_experience_approximate_sum ? v.mediation_experience_approximate_sum : '-'"></p>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider style="margin: 0"></v-divider>
        </v-col>
      </v-row>
      <v-row v-if="item.experience_type === 'Mediation'">
        <v-col cols="12" sm="3">
          <h6>Total Experience in Arbitration (in years):</h6>
          <p v-html="item.number_of_exp_in_mediation ? item.number_of_exp_in_mediation : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Are you already empaneled as an Mediator with any other Institution?</h6>
          <p v-html="item.exp_in_mediation_is_other_inst ? item.exp_in_mediation_is_other_inst : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3" v-if="item.exp_in_mediation_is_other_inst === 'Yes'">
          <h6>Other Institution Name:</h6>
          <p v-html="item.exp_in_mediation_other_inst_name ? item.exp_in_mediation_other_inst_name : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Number of Mediations facilitated by you:</h6>
          <p v-html="item.number_of_mediation_cases ? item.number_of_mediation_cases : '-'"></p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Awards</v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="item.user_awards.length > 0" v-for="(v, index) in item.user_awards"
             :key="index">
        <v-col cols="12" sm="3">
          <h6>Award PDF:</h6>
          <p v-if="v.award_pdf">
            <a :href="v.award_pdf_public_url" target="_blank">View</a>
          </p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Ref. No.:</h6>
          <p v-html="v.award_ref_no ? v.award_ref_no : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Date:</h6>
          <p v-html="v.award_date ? formatMomentDate(v.award_date) : '-'"></p>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Nature of dispute:</h6>
          <p v-html="v.award_nature_of_dispute ? v.award_nature_of_dispute : '-'"></p>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider style="margin: 0"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Publications</v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Publications:</h6>
          <p v-html="item.publications ? item.publications : '-'"></p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Professional Membership</v-card-title>
            <v-card-text class="white--text text-center">
              Please list all dispute resolution institutes/ organizations of which you are a member.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="item.user_professional_membership.length > 0" v-for="(v, index) in item.user_professional_membership"
             :key="index">
        <v-col cols="12" sm="4">
          <h6>Organization Name:</h6>
          <p v-html="v.professional_membership_organization_name ? v.professional_membership_organization_name : '-'"></p>
        </v-col>
        <v-col cols="12" sm="4">
          <h6>Date of Admission:</h6>
          <p v-html="v.professional_membership_date_of_admission ? formatMomentDate(v.professional_membership_date_of_admission) : '-'"></p>
        </v-col>
        <v-col cols="12" sm="4">
          <h6>Status:</h6>
          <p v-html="v.professional_membership_status ? v.professional_membership_status : '-'"></p>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider style="margin: 0"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Professional Certificates</v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="item.user_professional_certificates.length > 0"
             v-for="(v, index) in item.user_professional_certificates"
             :key="index">
        <v-col cols="12" sm="6">
          <h6>Certificate PDF:</h6>
          <p v-if="v.certificate_pdf">
            <a :href="v.certificate_pdf_public_url" target="_blank">View</a>
          </p>
        </v-col>
        <v-col cols="12" sm="6">
          <h6>Certificate Title:</h6>
          <p v-html="v.professional_certificate_title ? v.professional_certificate_title : '-'"></p>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider style="margin: 0"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Accreditation</v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="item.user_accreditation.length > 0" v-for="(v, index) in item.user_accreditation"
             :key="index">
        <v-col cols="12" sm="4">
          <h6>Accreditation PDF:</h6>
          <p v-if="v.accreditation_pdf">
            <a :href="v.accreditation_pdf_public_url" target="_blank">View</a>
          </p>
        </v-col>
        <v-col cols="12" sm="4">
          <h6>Accreditation For:</h6>
          <p v-html="v.accreditation_for ? v.accreditation_for : '-'"></p>
        </v-col>
        <v-col cols="12" sm="4">
          <h6>Accreditation From:</h6>
          <p v-html="v.accreditation_from ? v.accreditation_from : '-'"></p>
        </v-col>
        <v-col cols="12" sm="12">
          <v-divider style="margin: 0"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Additional Information</v-card-title>
            <v-card-text class="text-center white--text">
              Provide any other information that supports your application and evidences your
              professional standing in your field of work and your ability to deliver independent and
              unbiased rulings. Attach extra documents as necessary.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Additional Information:</h6>
          <p v-html="item.additional_information ? item.additional_information : '-'"></p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Biography</v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <h6>Biography:</h6>
          <p v-html="item.bio ? item.bio : '-'"></p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Social Media Links</v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <h6>Facebook:</h6>
          <p v-html="item.facebook_url ? item.facebook_url : '-'"></p>
        </v-col>
        <v-col cols="12" sm="6">
          <h6>Linkedin:</h6>
          <p v-html="item.linkedin_url ? item.linkedin_url : '-'"></p>
        </v-col>
        <v-col cols="12" sm="6">
          <h6>Twitter:</h6>
          <p v-html="item.twitter_url ? item.twitter_url : '-'"></p>
        </v-col>
        <v-col cols="12" sm="6">
          <h6>Instagram:</h6>
          <p v-html="item.instagram_url ? item.instagram_url : '-'"></p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
              color="#385F73"
              dark
          >
            <v-card-title class="justify-center">Preferences</v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12">
          <h6>Marketing Preferences:</h6>
          <p v-if="item.pre_offers === 1">
            I do not wish to receive offers and exclusive content from TCC ADR Centre and other carefully selected
            trusted partners that we think you will like. We will never share your personal information with them.
          </p>
        </v-col>
        <v-col cols="12" sm="12">
          <h6>Contact Preferences:</h6>
          <p v-if="item.pre_contact_telephone === 1">Telephone</p>
          <p v-if="item.pre_contact_post === 1">post</p>
          <p v-if="item.pre_contact_email === 1">email</p>
          <p v-if="item.pre_contact_sms === 1">SMS</p>
        </v-col>
        <v-col cols="12" sm="12">
          <h6>Areas of Interest:</h6>
          <p v-if="item.pre_aoi_adjudication === 1">Adjudication</p>
          <p v-if="item.pre_aoi_arbitration === 1">Arbitration</p>
          <p v-if="item.pre_aoi_mediation === 1">Mediation</p>
          <p v-if="item.pre_aoi_conciliation === 1">Conciliation</p>
          <p v-if="item.pre_aoi_expert_determination === 1">Expert Determination</p>
          <p v-if="item.pre_aoi_neutral_evaluation === 1">Neutral Evaluation</p>
          <p v-if="item.pre_aoi_dispute_boards === 1">Dispute Boards</p>
          <p v-if="item.pre_aoi_pro_bono === 1">Pro Bono</p>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>

export default {
  name: 'user-details',
  props: ['item'],
  data() {
    return {}
  },
  watch: {},
  mounted() {
  },
  methods: {},
  computed: {}
};
</script>